<template>
	<Header title="خدماتنا" link="خدماتنا" />
	<div class="container mx-auto px-5 md:px-8 py-12 grid grid-cols-1 md: md:grid-cols-4 gap-8 md-gap-16">
		<div class="md:col-span-2 tracking-wide leading-relaxed text-right my-5">
			<h3 class="">
				<span class="my-2 block text-black font-bold text-xl "> الخدمات</span>
				<span class="block h-1 w-16 rounded-md bg-primary"></span>
			</h3>
			<p class="text-infoGray  my-3">
				خدمات شركة زين لا تشمل فقط خدمات التموين الغذائي للمؤسسات والمنظمات و مواقع العمل ، ولكن أيضا تشمل
				التنظيف والغسيل و الصيانة وتوفير الطاقة البشرية.
			</p>
		</div>
		<div v-for="(item, index) in data" :key="index" class="border border-gray-100 rounded-md overflow-hidden">
			<img :src="item.img" class="h-40 w-full object-cover" alt="" />
			<div class="p-5 text-right">
				<h6 class="mb-3 text-primary ">{{ item.h1 }}</h6>
				<p class="text-servicesTextColor text-sm">{{ item.p }}</p>
			</div>
		</div>
	</div>
	<span class="block h-1 container bg-HeaderBg mx-auto my-12"></span>
	<div class="relative container mx-auto text-right bg-primary p-8 rounded-md text-white">
		<h2 class="font-bold text-xl my-4">مهام الشركة</h2>
		<img src="../assets/clipboard.png" alt="" class="absolute bottom-0 left-0" />
		<div class="grid grid-cols-1 md:grid-cols-2 gap-12">
			<ul>
				<li v-for="(item, index) in rightListItems" :key="index" class="flex items-center my-2 ">
					<span class="block rounded-md bg-primary border border-white h-3 w-3 mx-2"></span>
					<span class="w-11/12	">{{ item }}</span>
				</li>
			</ul>
			<ul>
				<li v-for="(item, index) in leftListItems" :key="index" class="flex items-center my-2 ">
					<span class="block rounded-md bg-primary border border-white h-3 w-3 mx-2"></span>
					<span class="w-11/12	">{{ item }}</span>
				</li>
			</ul>
		</div>
	</div>
	<div class="relative w-full bg-zainSerWotk py-12 text-right mt-16 pb-64 md:pb-48">
		<div class="container mx-auto px-5 md:px-8 grid md:grid-cols-2 gap-12 leading-relaxed tracking-wide">
			<div class="bg-white rounded-md p-8 relative">
				<img src="../assets/check.png" alt="" class="absolute bottom-0 left-0" />
				<h3 class="text-black text-lg font-bold mb-5">واجبات شركة زين</h3>
				<ul class="list-decimal text-md ">
					<li class="my-3 ">
						تلتزم شركة زين للتموين والنظافة بتأمين جميع موظفي السفرجة والنظافة المعينين للعمل في مبنى الزبون
						ضد أي ضرر أو أذى قد يلحق بأي منهم أثناء تواجدهم في مرافق الزبون المختلفة، وذلك لدى شركة تأمين
						محلية مرخصة.
					</li>
					<li class="my-3 ">
						تعتبر الشركة مسؤولة مسؤولية كاملة عن جميع الحقوق العمالية لموظفيها، مثل الرواتب والاجازات ونهاية
						الخدمة والتأمين الصحي وغيرها من الحقوق الخاصة بهم، التي يكفلها قانون العمل الليبي وأية قوانين
						أخرى ذات صلة.
					</li>
					<li class="my-3 ">
						تلتزم شركة زين بالمحافظة على سلامة محتويات المبنى من أثاث ومعدات وأجهزة مختلفة بحيث لا يمسها أي
						سوء أو تلف أو فقدان خلال قيام موظفي السفرجة والنظافة بأعمال التنظيف بداخلها، كما تلتزم الشركة
						بتغطية اصلاح واستبدال اي خلل او تلف ينجم عن قيامهم بالعمل.
					</li>
					<li class="my-3 ">القيام بأعمال البستنة والعناية بالأشجار والمسطحات الخضراء داخل المبنى.</li>
				</ul>
			</div>
			<div class="grid grid-cols-1 gap-4">
				<div class="bg-white rounded-lg p-8 ">
					<h6 class="text-black text-lg font-bold">خدمات السفرجة</h6>
					<ul class="list-decimal text-md">
						<li class="my-3">تقديم وجبات الفطور الخفيفة</li>
						<li class="my-3">تقديم المأكولات الجافة</li>
						<li class="my-3">
							تقديم المشروبات الساخنة مثل القهوة – الشاي- القهوة العربية وغيرها حسب رغبة الزبون.
						</li>
						<li class="my-3">تقديم المشروبات الباردة ( عصائر</li>
					</ul>
				</div>
				<div class="bg-white rounded-lg p-8 ">
					<h6 class="text-lg font-bold">القوى العاملة</h6>
					<p class="my-3">ستقوم شركة زين بتوفير القوى العاملة بالجدول الموضح أدناه</p>
					<table class="w-full my-5 text-center leading-relaxed tracking-wide">
						<thead>
							<tr class="py-2 bg-zainSerWotk">
								<th>
									الوصف الوظيفي
								</th>
								<th>
									العدد
								</th>
								<th>
									الخبرة
								</th>
							</tr>
						</thead>
						<tbody class="text-sm">
							<tr class="py-2">
								<td class="my-2">مشرف عام</td>
								<td class="my-2">1</td>
								<td class="my-2">5 سنوات على الأقل</td>
							</tr>
							<tr class="py-2 bg-zainSerWotk">
								<td>
									موظف نظافة
								</td>
								<td>3</td>
								<td>2 سنوات على الأقل</td>
							</tr>
							<tr class="py-2">
								<td>موظف للعناية بالحدائق</td>
								<td>1</td>
								<td>2 سنوات على الأقل</td>
							</tr>
							<tr class="py-2 bg-zainSerWotk">
								<td>موظف سفرجة</td>
								<td>1</td>
								<td>سنوات</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="absolute -bottom-16 w-full">
			<div
				data-aos="fade-up"
				class="container  mx-auto bg-primary px-12 py-16 rounded-lg flex items-center justify-center md:justify-between flex-wrap md:flex-nowrap text-right text-white"
			>
				<div class="leading-relaxed tracking-wide w-full md:w-auto text-center md:text-right">
					<h1 class="mb-2 font-bold text-3xl">للتواصل مع الشركة</h1>
					<h6 class="text-lg font-medium">لا تتردد في التواصل معنا لأي استفسار او خدمة</h6>
				</div>
				<router-link :to="{ name: 'Contact' }" class="bg-white rounded-md text-primary mt-5 md:mt-0 px-5 py-2"
					>صفحة التواصل</router-link
				>
			</div>
		</div>
	</div>
</template>
<script>
import { defineAsyncComponent } from 'vue';
const Header = defineAsyncComponent(() => import('../components/Header'));
import serv1 from '../assets/serv1.png';
import serv2 from '../assets/serv2.png';
import serv3 from '../assets/serv3.png';
import serv11 from '../assets/serv11.png';
import serv4 from '../assets/serv4.png';
import serv5 from '../assets/serv5.png';
import serv6 from '../assets/serv6.png';
import serv8 from '../assets/serv8.png';
import serv9 from '../assets/serv9.png';
import serv10 from '../assets/serv10.png';
export default {
	name: 'Services',
	data() {
		return {
			data: [
				{
					h1: 'تقديم خدمات الغرف وأعمال النظافة والغسيل.',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv1,
				},
				{
					h1: 'خدمات التموين بالمواد الغذائية والإعاشة',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv2,
				},
				{
					h1: 'خدمات النظافة',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv11,
				},
				{
					h1: 'إدارة وتشغيل المطاعم والكافيهات',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv3,
				},
				{
					h1: 'توفير خدمات التموين للطائرات.',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv4,
				},
				{
					h1: 'خدمات الضيافة والسفرجة.',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية ',
					img: serv5,
				},
				{
					h1: 'إجراء الصيانة الخفيفة للمباني بكافة أشكالها.',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv6,
				},
				{
					h1: 'تصميم المساحات الخضراء والعناية بها.',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv8,
				},
				{
					h1: 'مكافحة الآفات في المباني.',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv9,
				},
				{
					h1: 'إدارة النفايات والنفايات الطبية ',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: serv10,
				},
			],
			rightListItems: [
				' القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية:',
				'تقديم خدمات السفرجة اليومية طبقاً للمواصفات المتفق عليها.( القهوة- الشاي- الماء- مواد غذائية جافة)',
				'القيام بأعمال المناولة ونقل الأغراض والمستلزمات والأثاث وما شابهه من أي مكان إلى أي مكان يحدده طاقم المؤسسة.',
				'متابعة تنظيف مداخل المبنى الخارجية والداخلية ومواقف السيارات والساحات واالدراج والممرات والمنحدرات الخارجية والحدائق والمناطق الخضراء.',
				'متابعة تنظيف المكاتب والمرافق واالدراج والممرات والساحات الداخلية.',
				'متابعة تنظيف جميع القواطع والواجهات والرفوف والحواجز والأبواب الزجاجية، بما في ذلك واجهات المبنى الرئيسية والواجهات في الساحات الخارجية والواجهات الداخلية الزجاجية.',
				'وضع اكياس للنفايات في حاويات النفايات في جميع مرافق المبنى والساحات والشوارع والحدائق وأفنية المبنى الداخلية والخارجية والعمل على تفريغها بانتظام وعلى مدار اليوم ونقلها إلى نقاط التجميع المحددة للتخلص منها من قبل البلدية واألطراف الخارجية التي تختص بإعادة التدوير.',
			],
			leftListItems: [
				'تقديم خدمات السفرجة اليومية طبقاً للمواصفات المتفق عليها.( القهوة- الشاي- الماء- مواد غذائية جافة)',
				'متابعة تنظيف جميع القواطع و واجهات الألمنيوم والخشب الموجودة في كل مكان في المبنى على الحيطان والسقف، بما في ذلك السقف المستعارة وشرائح السقف المستعارة المصنوعة من الألمنيوم والخشب.',
				'.متابعة تنظيف جميع وحدات الإنارة الداخلية والخارجية بغض النظر عن أماكن تواجدها',
				'متابعة تنظيف التمديدات الكهربائية والميكانيكية الخارجية والتي تتعرض للغبار والأوساخ مثل الأنابيب والداكت الخاص بالتهوية وما شابهها',
				'وضع مواد النظافة ومعط ارت الجو وورق التواليت والتنشيف والصابون في جميع حمامات المبنى والتأكد من توفرها على مدار اليوم.',
				'توفير جميع مستلزمات النظافة من مواد وأدوات مع الاحتفاظ بمخزون يفي بمتطلبات العمل لمدة شهر واحد كحد أدنى.',
			],
		};
	},
	components: { Header },
};
</script>
